import React from "react";
import * as Icon from "react-feather";
import { getUser } from "utility/Common";
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Account",
  },
  {
    id: "create_account",
    title: "Buat User Baru",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["TI"],
    navLink: "/account/create_account",
  },
  {
    id: "ubah_password",
    title: "Ubah Password",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/account/ubah_password",
  },
  {
    type: "groupHeader",
    groupTitle: "Rekam Medik",
  },
  {
    id: "master_rekammedik",
    title: "Master Data",
    type: "collapse",
    icon: <Icon.Book size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "master_pakettindakan",
        title: "Paket Tindakan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/master/paket_tindakan",
      },
      {
        id: "master_pasien",
        title: "Pasien",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/master/pasien",
      },
      {
        id: "master_tindakan",
        title: "Tindakan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/master/tindakan",
      },
      {
        id: "master_jadwaldokter",
        title: "Jadwal Dokter",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/master/jadwal_dokter",
      },
      {
        id: "master_templateresep",
        title: "Template Resep",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/template_resep",
      },
      {
        id: "lab_kategori",
        title: "Lab Kategori",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/lab_kategori",
      },
      {
        id: "lab_catatan",
        title: "Lab Catatan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/lab_catatan",
      },
      {
        id: "lab_layanan",
        title: "Lab Layanan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/lab_layanan",
      },
      {
        id: "pengumuman_regonline",
        title: "Pengumuman Regonline",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/pengumuman_regonline",
      },
      {
        id: "template_penandaan",
        title: "Penandaan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/penandaan",
      },
      {
        id: "info_mobile",
        title: "Info Mobile",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
          "KAP",
        ],
        navLink: "/rekam_medik/master/info_mobile",
      },
    ],
  },
  {
    id: "registrasi_online",
    title: "Registrasi Online",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/registrasi_online",
  },
  {
    id: "registrasi",
    title: "Registrasi",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/registrasi",
  },
  {
    id: "selfregistrasi",
    title: "Self Registrasi",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/selfregister",
  },

  {
    id: "entry_tindakan",
    title: "Entry Tindakan",
    type: "item",
    icon: <Icon.FilePlus size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/entry_tindakan",
  },
  {
    id: "entry_pemakaian",
    title: "Entry Pemakaian",
    type: "item",
    icon: <Icon.LogOut size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/entry_pemakaian",
  },
  {
    id: "cetak_antrian_farmasi",
    title: "Antrian Farmasi",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/cetak_antrian_farmasi",
  },
  {
    id: "eradiologi",
    title: "eRadiologi",
    type: "item",
    icon: <Icon.Aperture size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/eradiologi",
  },
  {
    id: "elaboratorium",
    title: "eLaboratorium",
    type: "item",
    icon: <Icon.ZoomIn size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/elaboratorium",
  },
  {
    id: "jadwal_operasi",
    title: "Jadwal Operasi",
    type: "item",
    icon: <Icon.HardDrive size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/jadwal_operasi",
  },
  {
    id: "riwayat_pasien",
    title: "Riwayat Pasien",
    type: "item",
    icon: <Icon.Columns size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/riwayat_pasien",
  },
  {
    id: "riwayat_resep",
    title: "Riwayat Resep",
    type: "item",
    icon: <Icon.Database size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/rekam_medik/riwayat_resep",
  },
  {
    id: "ubahdata_rekammedik",
    title: "Ubah Data",
    type: "collapse",
    icon: <Icon.Edit3 size={20} />,
    children: [
      {
        id: "ubahdata_jaminan",
        title: "Jaminan Tagihan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/ubah_data/jaminan",
      },
      {
        id: "ubahdata_kwitansi",
        title: "Kwitansi",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/rekam_medik/ubah_data/kwitansi/reguler",
      },
    ],
  },
  {
    id: "selisih_naik_kelas_bpjs",
    title: "Selisih Naik Kelas BPJS",
    type: "item",
    icon: <Icon.Sunrise size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/rekam_medik/selisihNaikKelasBPJS",
  },
  //mcu
  {
    id: "mcu_sirs",
    title: "MCU",
    type: "collapse",
    icon: <Icon.Activity size={20} />,
    children: [
      {
        id: "mcu_pemeriksaan_papsmear",
        title: "Papsmear",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/pemeriksaanPapsmear`,
      },
      {
        id: "mcu_daftar_pasien",
        title: "List EForm MCU",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/daftar_pasien`,
      },
      {
        id: "mcu_hasil_fisik",
        title: "Hasil Fisik",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_fisik`,
      },
      {
        id: "mcu_hasil_tht",
        title: "Hasil THT",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_tht`,
      },
      {
        id: "mcu_hasil_saraf",
        title: "Hasil Saraf",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_saraf`,
      },
      {
        id: "mcu_hasil_mata",
        title: "Hasil Mata",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_mata`,
      },
      {
        id: "mcu_hasil_gigi",
        title: "Hasil Gigi",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_gigi`,
      },
      {
        id: "mcu_hasil_ecg",
        title: "Hasil ECG",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_ecg`,
      },
      {
        id: "mcu_hasil_spiro",
        title: "Hasil Spiro",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/rekam_medik/mcu/hasil_spiro`,
      },
      {
        id: "mcu",
        title: "MCU",
        type: "external-link",
        icon: <Icon.ExternalLink size={20} />,
        onClick: "window.open('your_url')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "http://139.255.11.206:8083/checkup2016/",
        newTab: true,
      },
    ],
  },
  //mcu

  // {
  //   id: "mcu",
  //   title: "MCU",
  //   type: "external-link",
  //   icon: <Icon.ExternalLink size={20} />,
  //   permissions: [
  //     "LABORAT_ROLE",
  //     "DIV_KALIM",
  //     "LOGISTIK_ROLE",
  //     "LOGISTIK",
  //     "FARMASI",
  //     "FAR_CO",
  //     "ABC",
  //     "PENGADAAN_ROLE",
  //     "FARRAJAL",
  //     "TI",
  //     "SDM",
  //     "LAYANAN_CO",
  //     "FARMASIK3",
  //     "ADMIN",
  //     "KASIR_ROLE",
  //     "FULL_RSPG",
  //     "FARRAWIN",
  //     "LOBBY_ROLE",
  //     "LAYANAN",
  //     "DIV_GRAHU",
  //     "PERAWAT_ROLE",
  //     "FULL_RSPG1",
  //     "KEUROLE",
  //     "KAP",
  //   ],
  //   navLink: "http://139.255.11.206:8083/checkup2016/",
  // },
  {
    type: "groupHeader",
    groupTitle: "Inventory",
  },
  {
    id: "master_inventory",
    title: "Master Data",
    type: "collapse",
    icon: <Icon.Book size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "master_obat",
        title: "Obat",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/master/obat",
      },
    ],
  },
  {
    id: "eresep",
    title: "eResep",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/inventory/eresep",
  },
  {
    id: "resep_obat_inden",
    title: "Resep Inden",
    type: "item",
    icon: <Icon.FileMinus size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/inventory/resep_obat_inden",
  },
  {
    id: "penjualan_apotik",
    title: "Penjualan Apotik",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/inventory/penjualan_apotik",
  },

  {
    id: "retur_farmasi",
    title: "Retur Farmasi",
    type: "item",
    icon: <Icon.RefreshCw size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/inventory/retur_farmasi",
  },
  {
    id: "si",
    title: "SI",
    type: "collapse",
    icon: <Icon.FolderPlus size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "si_permintaan_pembelian",
        title: "PP",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/si/permintaan_pembelian",
      },
      {
        id: "si_sk_barang",
        title: "SK Barang",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/si/sk_barang",
      },
      {
        id: "si_order_pembelian",
        title: "Order Pembelian",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/si/order_pembelian",
      },
      {
        id: "si_terima_barang",
        title: "Terima Barang",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/si/terima_barang",
      },
      {
        id: "si_retur_tb",
        title: "Retur TB",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/si/retur_tb",
      },
      {
        id: "si_permintaan_transfer_obat",
        title: "Permintaan Transfer Obat",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/si/permintaan_transfer_obat",
      },
      {
        id: "si_transfer_ke_unit",
        title: "Transfer ke Unit",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/si/transfer_ke_unit",
      },
    ],
  },
  //
  // {
  //   id: "atk",
  //   title: "ATK",
  //   type: "collapse",
  //   icon: <Icon.Archive size={20} />,
  //   // badge: "warning",
  //   // badgeText: "2",
  //   children: [
  //     {
  //       id: "nsi_permintaan_pembelian",
  //       title: "PP",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //         "KAP",
  //       ],
  //       navLink: "/inventory/atk/permintaan_pembelian",
  //     },
  //     {
  //       id: "atk_sk_barang",
  //       title: "SK Barang",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //       ],
  //       navLink: "/inventory/atk/sk_barang",
  //     },
  //     {
  //       id: "atk_order_pembelian",
  //       title: "Order Pembelian",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //         "KAP",
  //       ],
  //       navLink: "/inventory/atk/order_pembelian",
  //     },
  //     {
  //       id: "atk_terima_barang",
  //       title: "Terima Barang",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //         "KAP",
  //       ],
  //       navLink: "/inventory/atk/terima_barang",
  //     },
  //     {
  //       id: "atk_retur_tb",
  //       title: "Retur TB",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //       ],
  //       navLink: "/inventory/atk/retur_tb",
  //     },
  //     {
  //       id: "atk_permintaan_transfer_obat",
  //       title: "Permintaan Transfer Obat",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //         "KAP",
  //       ],
  //       navLink: "/inventory/atk/permintaan_transfer_obat",
  //     },
  //     {
  //       id: "atk_transfer_ke_unit",
  //       title: "Transfer ke Unit",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: [
  //         "LABORAT_ROLE",
  //         "DIV_KALIM",
  //         "LOGISTIK_ROLE",
  //         "LOGISTIK",
  //         "FARMASI",
  //         "FAR_CO",
  //         "ABC",
  //         "PENGADAAN_ROLE",
  //         "FARRAJAL",
  //         "TI",
  //         "SDM",
  //         "LAYANAN_CO",
  //         "FARMASIK3",
  //         "ADMIN",
  //         "KASIR_ROLE",
  //         "FULL_RSPG",
  //         "FARRAWIN",
  //         "LOBBY_ROLE",
  //         "LAYANAN",
  //         "DIV_GRAHU",
  //         "PERAWAT_ROLE",
  //         "FULL_RSPG1",
  //         "KEUROLE",
  //         "KAP",
  //       ],
  //       navLink: "/inventory/atk/transfer_ke_unit",
  //     },
  //   ],
  // },
  //
  {
    id: "nsi",
    title: "NSI",
    type: "collapse",
    icon: <Icon.FolderMinus size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "nsi_permintaan_pembelian",
        title: "PP",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/nsi/permintaan_pembelian",
      },
      {
        id: "nsi_sk_barang",
        title: "SK Barang",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/nsi/sk_barang",
      },
      {
        id: "nsi_order_pembelian",
        title: "Order Pembelian",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/nsi/order_pembelian",
      },
      {
        id: "nsi_terima_barang",
        title: "Terima Barang",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/nsi/terima_barang",
      },
      {
        id: "nsi_retur_tb",
        title: "Retur TB",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["PENGADAAN_ROLE", "TI", "ADMIN"],
        navLink: "/inventory/nsi/retur_tb",
      },
      {
        id: "nsi_permintaan_transfer_obat",
        title: "Permintaan Transfer Obat",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/nsi/permintaan_transfer_obat",
      },
      {
        id: "nsi_transfer_ke_unit",
        title: "Transfer ke Unit",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/nsi/transfer_ke_unit",
      },
      {
        id: "laundry",
        title: "Order Laundry",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/laundry",
      },
      {
        id: "laundry_approvel",
        title: "Approval Laundry",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/laundry/approval",
      },
    ],
  },
  {
    id: "up",
    title: getUser()?.role === "KAP" ? "" : "UP",
    type: getUser()?.role === "KAP" ? "" : "collapse",
    icon: getUser()?.role === "KAP" ? <></> : <Icon.Folder size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "terimabarang_up",
        title: "Terima Barang",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/inventory/up/terima_barang",
      },
      {
        id: "penjualan_up",
        title: "Penjualan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/inventory/up/penjualan",
      },
    ],
  },
  {
    id: "jasa",
    title: "Jasa",
    type: "collapse",
    icon: <Icon.Zap size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "spk",
        title: "SPK",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/jasa/spk",
      },
      {
        id: "ok",
        title: "Order Kerja",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/jasa/order_kerja",
      },
      {
        id: "mou&adendum",
        title: "MoU & Adendum",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/jasa/mou_dan_adendum",
      },
      {
        id: "ba",
        title: "Berita Acara",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/inventory/jasa/berita_acara",
      },
    ],
  },
  {
    id: "stock_opname",
    title: "Stock Opname",
    type: "collapse",
    icon: <Icon.Box size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "stock_count_sheet",
        title: "Count Sheet",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/inventory/stock_opname/stock_count_sheet",
      },
      {
        id: "stock_count_input",
        title: "Count Input",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/inventory/stock_opname/stock_count_input",
      },
    ],
  },
  {
    id: "jual_ke_gpm",
    title: "Jual ke GPM",
    type: "item",
    icon: <Icon.ArrowRight size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/inventory/jual_ke_gpm",
  },
  {
    id: "aset_inventarisasi",
    title: "Aset dan Inventarisasi",
    type: "item",
    icon: <Icon.ExternalLink size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/inventory/aset_inventarisasi",
  },
  {
    type: "groupHeader",
    groupTitle: "Keuangan",
  },
  {
    id: "kasir_layanan",
    title: "Kasir Layanan",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/kasir_layanan",
  },
  {
    id: "nota_rirj",
    title: "Nota RI/RJ",
    type: "item",
    icon: <Icon.Printer size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/nota_rirj",
  },
  {
    id: "deposit",
    title: "Deposit",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/keuangan/deposit",
  },
  {
    id: "approval_pendapatan_kasir",
    title: "Approval Pendapatan Kasir",
    type: "item",
    icon: <Icon.FastForward size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/approval_pendapatan_kasir",
  },
  {
    id: "belum_bayar_farmasi",
    title: "Blm Bayar Farmasi",
    type: "item",
    icon: <Icon.ZapOff size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/belum_bayar_farmasi",
  },
  {
    id: "reimburse",
    title: "Reimburse",
    type: "item",
    icon: <Icon.Minimize2 size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/keuangan/reimburse",
  },
  {
    id: "rekap_rs_luar",
    title: "Rekap RS Luar",
    type: "item",
    icon: <Icon.Minimize size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/keuangan/rekap_rs_luar",
  },
  {
    id: "excel_tagihan",
    title: "Excel Tagihan",
    type: "item",
    icon: <Icon.Map size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/excel_tagihan",
  },
  {
    id: "faktur",
    title: "Faktur",
    type: "item",
    icon: <Icon.Mail size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/faktur",
  },
  {
    id: "kasbon",
    title: "Kasbon",
    type: "collapse",
    icon: <Icon.CornerLeftDown size={20} />,
    children: [
      {
        id: "kasbon_permintaan",
        title: "Permintaan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kasbon/permintaan",
      },
      {
        id: "kasbon_ptj",
        title: "PTJ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kasbon/ptj",
      },
      {
        id: "kasbon_approvebod",
        title: "Approve HO",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["TI", "KEUROLE"],
        navLink: "/keuangan/kasbon/approve_ho",
      },
      {
        id: "kasbon_rekap",
        title: "Rekap",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kasbon/rekap",
      },
    ],
  },
  {
    id: "kas_kecil",
    title: "Kas Kecil",
    type: "collapse",
    icon: <Icon.CornerDownRight size={20} />,
    children: [
      {
        id: "kaskecil_permintaan",
        title: "Permintaan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kas_kecil/permintaan",
      },
      {
        id: "kaskecil_ptj",
        title: "PTJ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kas_kecil/ptj",
      },
      {
        id: "kaskecil_approvebod",
        title: "Approve HO",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["TI", "KEUROLE"],
        navLink: "/keuangan/kas_kecil/approve_ho",
      },
      {
        id: "kaskecil_rekap",
        title: "Rekap",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/kas_kecil/rekap",
      },
    ],
  },
  {
    id: "uang_muka",
    title: "Uang Muka",
    type: "collapse",
    icon: <Icon.CornerDownLeft size={20} />,
    children: [
      {
        id: "uangmuka_permintaan",
        title: "Permintaan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/uang_muka/permintaan",
      },
      {
        id: "uangmuka_ptj",
        title: "PTJ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/uang_muka/ptj",
      },
      {
        id: "uangmuka_approveho",
        title: "Approve HO",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["TI", "KEUROLE"],
        navLink: "/keuangan/uang_muka/approve_ho",
      },
      {
        id: "uangmuka_rekap",
        title: "Rekap",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/uang_muka/rekap",
      },
    ],
  },
  {
    id: "notakredit",
    title: "Nota Kredit",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/keuangan/nota_kredit",
  },
  {
    id: "kasbank",
    title: "Kas / Bank",
    type: "item",
    icon: <Icon.Maximize2 size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/keuangan/kasbank",
  },
  {
    id: "bpjs",
    title: "BPJS",
    type: "item",
    icon: <Icon.Circle size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    // navLink:
    //   getUser()?.sbu === "RSPG"
    //     ? "http://192.168.10.245/lpk.ayani/"
    //     : "http://192.168.10.245/lpk/",
    navLink: "/keuangan/lpk/",
  },
  {
    id: "tagihan_bpjs",
    title: "Tagihan BPJS",
    type: "collapse",
    icon: <Icon.Clipboard size={20} />,
    children: [
      {
        id: "kelengkapan_berkas",
        title: "Kelengkapan Berkas",
        type: "item",
        icon: <Icon.File size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/tagihan_bpjs",
      },
      {
        id: "coder_bpjs",
        title: "Verif INACBG",
        type: "item",
        icon: <Icon.File size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/keuangan/tagihan_bpjs_coder",
      },
      
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "Akuntansi",
  },
  {
    id: "approval_akuntansi",
    title: getUser()?.role === "KAP" ? "" : "Approval",
    type: getUser()?.role === "KAP" ? "" : "collapse",
    icon: getUser()?.role === "KAP" ? <></> : <Icon.CheckCircle size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "approval_tindakan",
        title: "Tindakan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/akuntansi/approval/tindakan",
      },
      {
        id: "approval_penjualan",
        title: "Penjualan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
        ],
        navLink: "/akuntansi/approval/penjualan",
      },
    ],
  },
  {
    id: "posting_jurnal",
    title: "Posting Jurnal",
    type: "item",
    icon: <Icon.Truck size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "/akuntansi/posting_jurnal",
  },
  {
    id: "buku_besar",
    title: "Buku Besar",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/akuntansi/buku_besar",
  },
  {
    id: "aging_hutang",
    title: "Aging Hutang",
    type: "item",
    icon: <Icon.Battery size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/akuntansi/aging_hutang",
  },
  {
    id: "jurnal_memorial",
    title: "Jurnal Memorial",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
    ],
    navLink: "/akuntansi/jurnal_memorial",
  },
  {
    type: "groupHeader",
    groupTitle: "Remunerasi",
  },
  {
    id: "master_remunerasi",
    title: "Master Data",
    type: "collapse",
    icon: <Icon.Book size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "master_honordokter",
        title: "Honor Dokter",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/master_data/honor_dokter",
      },
      {
        id: "master_dokter",
        title: "Dokter",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/master_data/dokter",
      },
    ],
  },
  {
    id: "dokter_remunerasi",
    title: "Dokter",
    type: "collapse",
    icon: <Icon.UserCheck size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "approval_honordokter",
        title: "Approval Honor",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/approval_honor",
      },
      {
        id: "approval_ho_honordokter",
        title: "Approval HO",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["TI", "SDM", "KEUROLE"],
        navLink: "/remunerasi/dokter/approval_ho",
      },
      {
        id: "input_honordokter_lain",
        title: "Input Honor Lain",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/input_honor_lain",
      },
      {
        id: "report_honor_dokter",
        title: "Report Honor",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/report_honor",
      },
      {
        id: "rekap_honor_dokter",
        title: "Rekap Honor",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/rekap_honor",
      },
      {
        id: "honor_dokter_persbu",
        title: "Honor Per SBU",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/honor_persbu",
      },
      {
        id: "honor_dokter_perdokter",
        title: "Honor Per Dokter",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/honor_perdokter",
      },
      {
        id: "honor_dokter_semuasbu",
        title: "Honor Semua SBU",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/remunerasi/dokter/honor_semuasbu",
      },
    ],
  },
  {
    id: "laporan_jasa",
    title: "Laporan Jasa",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
      "KAP",
    ],
    navLink: "/remunerasi/laporan_jasa",
  },
  {
    type: "groupHeader",
    groupTitle: "Payroll",
  },
  {
    id: "portalsdm",
    title: "Portal SDM",
    type: "external-link",
    icon: <Icon.ExternalLink size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "KAP",
    ],
    navLink: "http://139.255.11.206:8083/sdmnew/",
  },

  {
    type: "groupHeader",
    groupTitle: "Report Manajemen",
  },
  {
    id: "rekammedik_report",
    title: "Rekam Medik",
    type: "collapse",
    icon: <Icon.Clipboard size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "report_dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.PieChart size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/dashboard",
      },
      {
        id: "report_kunjungan_unit",
        title: "Trend Kunj & Omset",
        type: "item",
        icon: <Icon.TrendingUp size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/trend",
      },
      {
        id: "report_laporan_kunjungan",
        title: "Laporan Kunjungan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/laporan_kunjungan",
      },
      {
        id: "report_laporan_terintegrasi",
        title: "Sipegas",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/laporan_terintegrasi",
      },
      {
        id: "report_laporan_validasi_tindakan",
        title: "Laporan Validasi Tindakan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/laporan_validasi_tindakan",
      },
      {
        id: "report_laporan_rekam_medik",
        title: "Laporan Rekam Medik",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/laporan_rekam_medik",
      },
      {
        id: "report_harian_rawin",
        title: "Harian Rawin",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/report/rekam_medik/laporan_harian_rawin",
      },
    ],
  },
  {
    id: "inventory_report",
    title: getUser()?.role === "KAP" ? "" : "Inventory",
    type: getUser()?.role === "KAP" ? "" : "collapse",
    icon: getUser()?.role === "KAP" ? <></> : <Icon.Archive size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "transfer_obat_belum",
        title: "TR Obat Belum",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/inventory/transfer_obat_belum`,
      },
      {
        id: "persediaan_unit",
        title: "Persediaan Unit",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/inventory/persediaan_unit`,
      },
      {
        id: "laporan_persediaan",
        title: "Laporan Persediaan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/inventory/laporan_persediaan`,
      },
      {
        id: "laporan_narkotik",
        title: "Laporan Narkotik",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/inventory/laporan_narkotik`,
      },
      {
        id: "laporan_trnsi",
        title: "Rekap TR NSI",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/inventory/laporan_trnsi`,
      },
    ],
  },
  {
    id: "keuangan_report",
    title: "Keuangan",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "rekap_tagihan",
        title: "Rekap Tagihan",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/rekap_tagihan`,
      },
      {
        id: "pendapatan_kasirrj",
        title: "Pend. Kasir RJ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/pendapatan_kasir_rj`,
      },
      {
        id: "laporan_omset",
        title: "Laporan Omset",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/laporan_omset`,
      },
      {
        id: "laporan_embalase",
        title: "Laporan Embalase",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/laporan_embalase`,
      },
      {
        id: "laporan_honor_apoteker",
        title: "Laporan Honor Apoteker",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/laporan_honor_apoteker`,
      },
      {
        id: "rekap_deposit",
        title: "Rekap Deposit",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/rekap_deposit`,
      },
      {
        id: "rekap_penjualan_farmasi",
        title: "Rekap Penj. Farmasi",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/rekap_penjualan_farmasi`,
      },
      {
        id: "monitoring_asuransi",
        title: "Monitoring Asuransi",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/monitoring_asuransi`,
      },
      {
        id: "plafonRI",
        title: "Plafon RI",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/keuangan/plafon_ri`,
      },
    ],
  },
  {
    id: "sdm_report",
    title: "SDM",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Server size={12} />,
        permissions: ["TI", "SDM", "ADMIN", "FULL_RSPG"],
        navLink: `/report/sdm/dashboard`,
      },
      {
        id: "strava",
        title: "Monitoring Strava",
        type: "item",
        icon: <Icon.Server size={12} />,
        permissions: [
          "TI",
          "SDM",
          "ADMIN",
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/sdm/monitoring_aktivitas`,
      },
      {
        id: "updatestrava",
        title: "Update Aktivitas",
        type: "item",
        icon: <Icon.Server size={12} />,
        permissions: [
          "TI",
          "SDM",
          "ADMIN",
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/report/sdm/update_aktivitas`,
      },
    ],
  },

  {
    type: "groupHeader",
    groupTitle: "Dashboard BPJS",
  },
  {
    id: "antrean_rs",
    title: "Anrean RS",
    type: "collapse",
    icon: <Icon.ArrowRight size={20} />,
    children: [
      {
        id: "bpjs_dashboardpertanggal",
        title: "Pertanggal",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/bpjs/dashboard_pertanggal`,
      },
      {
        id: "dashboard_perbulan",
        title: "Perbulan",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/bpjs/dashboard_perbulan`,
      },
    ],
  },

  {
    id: "vclaim",
    title: "Vclaim",
    type: "collapse",
    icon: <Icon.ArrowRight size={20} />,
    children: [
      {
        id: "data_kunjungan",
        title: "Data Kunjungan",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/bpjs/data_kunjungan`,
      },
      {
        id: "data_klaim",
        title: "Data Klaim",
        type: "item",
        icon: <Icon.FileText size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: `/bpjs/data_klaim`,
      },
    ],
  },

  {
    id: "info_kamar",
    title: "Ketersediaan Kamar BPJS",
    type: "item",
    icon: <Icon.ArrowRight size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/bpjs/ketersediaan_kamar`,
  },
  {
    type: "groupHeader",
    groupTitle: "Full Screen",
  },
  {
    id: "dashboard_jaop",
    title: "Jadwal Operasi",
    type: "item",
    icon: <Icon.ArrowRight size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/full_screen/jadwal_operasi`,
  },
  {
    id: "dashboard_farmasi",
    title: "Farmasi",
    type: "item",
    icon: <Icon.ArrowRight size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/full_screen/dashboard_farmasi`,
  },
  {
    id: "antrian_farmasi",
    title: "Farmasi Baru",
    type: "item",
    icon: <Icon.ArrowRight size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/full_screen/antrian_farmasi`,
  },

  {
    type: "groupHeader",
    groupTitle: "Document",
  },
  {
    id: "link_dokumen",
    title: "Link Document",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/dokumen/link_dokumen`,
  },
  {
    id: "berkas_tagihan",
    title: "Info Berkas Tagihan",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/dokumen/berkas_tagihan`,
  },
  {
    id: "indikator_mutu",
    title: "Indikator Mutu",
    type: "collapse",
    icon: <Icon.Clipboard size={20} />,
    children: [
      {
        id: "indikator_mutu_nasional",
        title: "Nasional",
        type: "item",
        icon: <Icon.FilePlus size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/dokumen/indikator_mutu_nasional",
      },
      {
        id: "indikator_mutu_prioritas_rs",
        title: "Prioritas Rumah Sakit",
        type: "item",
        icon: <Icon.FilePlus size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/dokumen/indikator_mutu_prioritas_rs",
      },
      {
        id: "indikator_mutu_prioritas_unit",
        title: "Prioritas Unit",
        type: "item",
        icon: <Icon.FilePlus size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/dokumen/indikator_mutu_prioritas_unit",
      },
    ],
  },
  
  {
    id: "layanan_pelanggan",
    title: "Layanan Pelanggan",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: [
      "LABORAT_ROLE",
      "DIV_KALIM",
      "LOGISTIK_ROLE",
      "LOGISTIK",
      "FARMASI",
      "FAR_CO",
      "ABC",
      "PENGADAAN_ROLE",
      "FARRAJAL",
      "TI",
      "SDM",
      "LAYANAN_CO",
      "FARMASIK3",
      "ADMIN",
      "KASIR_ROLE",
      "FULL_RSPG",
      "FARRAWIN",
      "LOBBY_ROLE",
      "LAYANAN",
      "DIV_GRAHU",
      "PERAWAT_ROLE",
      "FULL_RSPG1",
      "KEUROLE",
      "DOKTER",
    ],
    navLink: `/dokumen/layanan_pelanggan`,
  },

  {
    type: "groupHeader",
    groupTitle: "SOFTCOPY",
  },
  {
    id: "softcopy_obat",
    title: "Softcopy Obat",
    type: "collapse",
    icon: <Icon.Clipboard size={20} />,
    // badge: "warning",
    // badgeText: "2",
    children: [
      {
        id: "softcopy_obat_kronis",
        title: "Softcopy Obat Kronis",
        type: "item",
        icon: <Icon.FilePlus size={20} />,
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "KAP",
        ],
        navLink: "/softcopy/softcopy_obat_kronis",
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "PORTAL KEMKES",
  },
  {
    id: "portal_kemkes",
    title: "Portal",
    type: "collapse",
    icon: <Icon.Link size={20} />,
    children: [
      {
        id: "rs_online_kemkes",
        title: "RS Online",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://sirs.kemkes.go.id/fo')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://sirs.kemkes.go.id/fo",
        newTab: true,
      },
      {
        id: "siranap",
        title: "SIRANAP",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick:
          "window.open('https://yankes.kemenkes.go.id/app/siranap/rumah_sakit')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://yankes.kemenkes.go.id/app/siranap/rumah_sakit",
        newTab: true,
      },
      {
        id: "operasional_ihc",
        title: "Operasional IHC",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick:
          "window.open('https://app.pertamaedika.co.id/portaloperasional/')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://app.pertamaedika.co.id/portaloperasional/",
        newTab: true,
      },
      {
        id: "sisrute",
        title: "SISRUTE",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://sisrute.kemkes.go.id/')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://sisrute.kemkes.go.id/",
        newTab: true,
      },
      {
        id: "sirs_jatim",
        title: "SIRS Jatim",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('http://sirs.dinkes.jatimprov.go.id/index.php')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "http://sirs.dinkes.jatimprov.go.id/index.php",
        newTab: true,
      },
      {
        id: "sismal",
        title: "SISMAL",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://sismal.kemkes.go.id/zero-report')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://sismal.kemkes.go.id/zero-report",
        newTab: true,
      },
      {
        id: "mpdn",
        title: "MPDN",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://mpdn.kemkes.go.id/')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://mpdn.kemkes.go.id/",
        newTab: true,
      },
      {
        id: "ekohort",
        title: "EKOHORT",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://ekohort.kemkes.go.id/')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://ekohort.kemkes.go.id/",
        newTab: true,
      },
      {
        id: "inm",
        title: "INM",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://mutufasyankes.kemkes.go.id/')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://mutufasyankes.kemkes.go.id/",
        newTab: true,
      },
      {
        id: "siha",
        title: "SIHA",
        type: "external-link",
        icon: <Icon.Activity size={20} />,
        onClick: "window.open('https://sihapims.kemkes.go.id/login')",
        permissions: [
          "LABORAT_ROLE",
          "DIV_KALIM",
          "LOGISTIK_ROLE",
          "LOGISTIK",
          "FARMASI",
          "FAR_CO",
          "ABC",
          "PENGADAAN_ROLE",
          "FARRAJAL",
          "TI",
          "SDM",
          "LAYANAN_CO",
          "FARMASIK3",
          "ADMIN",
          "KASIR_ROLE",
          "FULL_RSPG",
          "FARRAWIN",
          "LOBBY_ROLE",
          "LAYANAN",
          "DIV_GRAHU",
          "PERAWAT_ROLE",
          "FULL_RSPG1",
          "KEUROLE",
          "DOKTER",
        ],
        navLink: "https://sihapims.kemkes.go.id/login",
        newTab: true,
      },
    ],
  },
];

export default navigationConfig;
